<template>
  <div
    class="annotation__container overflow-y-auto shadow rounded-xl pr-0"
    id="annotation_container"
    ref="infoBox"
  >
    <div class="header">
      <h1 class="annotation_container__title">
        {{ annotation.name }}
      </h1>
      <span class="annotation_container__time-ago">
        {{ ` ( ${getTimeAgo(annotation.datepointHuman)} ${$t('back')} )` }}
      </span>
      <div
        v-for="(row, index) in rows.main"
        :key="index + '2'"
        class="header_info"
      >
        <div v-if="row.key !== 'unit_name'">
          <p class="title">
            {{
              row.key === 'datepoint'
                ? $t('annotation.data_date')
                : row.name + ':'
            }}
          </p>
          <span class="title">{{
            row.key === 'datepoint' ? annotation.datepointHuman : row.value
          }}</span>
        </div>
      </div>
      <i
        class="el-icon-close text-xl cursor-pointer text-lightblue font-bold absolute mt-4 mr-2"
        style="top: 0.1rem; right: 0.1rem"
        @click="handlerClose"
      />
    </div>

    <el-collapse v-model="activeNames" class="without-main-row">
      <el-collapse-item
        class="flex flex-col"
        v-for="(row, index) in witoutMainRows"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <span
            v-if="index !== 'main'"
            class="flex items-center h-full text-darkblue uppercase font-bold justify-between flex font-SourceSansPro title p-2 ml-1 sub-title pl-4 w-full"
            @click="toggleItem(index)"
          >
            {{ getRowName(index) }}
          </span>
        </template>
        <div
          v-if="index !== 'main'"
          class="grid grid-cols-2 w-full px-2 pl-4 mr-2"
        >
          <div
            class="flex border-b-2 mb-2 flex-col"
            v-for="(el, idx) in filterRowWithoutAddress(row)"
            :key="idx"
          >
            <span class="mini-title pr-2">
              {{ el.name + ' :' }}
            </span>
            <span class="mini-title-value mb-2 pb-1 mr-2">
              {{ el.value }}
            </span>
          </div>
        </div>
        <div
          v-for="(el, index) in filterRowWithAddress(row)"
          class="flex px-2 mb-2 pb-1"
          :key="index"
        >
          <span class="mr-2 mini-title pl-2" style="width: 20%">
            {{ el.name }}
          </span>
          <span class="mini-title-value">
            {{ el.value }}
          </span>
        </div>
      </el-collapse-item>
    </el-collapse>

    <el-collapse
      v-if="extraInfo.length"
      class="pb-1 w-full bg-white overflow-y-auto report-annotation pr-0"
    >
      <el-collapse-item>
        <template slot="title">
          <div
            class="cursor-pointer flex justify-between items-center sub-title w-full ml-1"
            @click="showExtra"
          >
            <span
              class="text-darkblue uppercase font-bold font-SourceSansPro text-base px-4"
              style="font-size: 12px"
            >
              {{ $t('annotation.reports') }}
            </span>
          </div>
        </template>
        <el-collapse class="report-details" accordion="accordion">
          <el-collapse-item
            class="pl-6"
            v-for="(extra, index) in extraInfo"
            :name="index"
            :key="index"
          >
            <template slot="title">
              <div class="w-full flex justify-between">
                <span class="collapse-title">
                  {{ extra.name }}
                </span>
                <span class="collapse-title mr-2 collapse-time">
                  {{ extra.value }}
                </span>
              </div>
            </template>
            <div class="flex flex-col">
              <div
                class="flex border-b border-divider py-2"
                v-for="(item, index) in extra.columns"
                :key="index"
              >
                <span
                  class="text-darkblue font-semibold font-SourceSansPro"
                  style="width: 30%"
                >
                  {{ item.name }}
                </span>
                <span
                  class="mx-2 text-annotationColor font-semibold font-SourceSansPro text-right"
                >
                  {{ item.value }}
                </span>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { objectsApi } from '@/api'
import getLastUpdateTimeObject from '@/utils/getLastUpdateTimeObject'
import L from '@/utils/leaflet.js'
import { mapState } from 'vuex'

export default {
  props: {
    annotation: {
      type: Object,
      default: () => {}
    },
    yAxis: {
      type: Number,
      default: 0
    },
    xAxis: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      screenHeight: '',
      height: '',
      isExtraInfo: false,
      extraInfo: [],
      currentWidth: '',
      title: '',
      timesAgo: '',
      driver: '',
      activeIndex: null,
      activeNames: ['gps', 'sensors']
    }
  },

  computed: {
    ...mapState('map', {
      eyeTriggered: (state) => state.eyeTriggered.value
    }),

    ...mapState('login', ['me']),
    ...mapState('company', {
      currentTime: (state) => state.currentTime
    }),
    rows() {
      const anno = this.annotation
        ? this.annotation.columns.filter((k) => k.key.charAt(0) !== '_')
        : []

      const formattedAnno = anno.reduce(function (results, row) {
        ;(results[row.group] = results[row.group] || []).push(row)
        return results
      }, {})
      anno.forEach((row, index) => {
        if (row.group === 'main') {
          if (row.key === 'datepoint') {
            this.timesAgo = this.rowValue(row, index)
          } else if (row.key === 'unit_name') {
            this.title = row.value
          } else if (row.key === 'driver') {
            this.driver = row.value
          }
        }
      })

      return formattedAnno
    },

    witoutMainRows() {
      const { main, ...rows } = this.rows
      return rows
    },

    style() {
      const anno = this.annotation
        ? this.annotation.columns.filter((k) => k.key.charAt(0) !== '_')
        : []

      return {
        top: `0px`,
        right: `65px`,
        width: '418px',
        height: anno.length > 13 ? '65vh' : '',

        marginTop: '4rem',
        marginBottom: '1rem',
        scrollbarWidth: 'auto'
      }
    },
    cssVar() {
      return {
        // '--top-var': `66px`,
        'font-size': '10px'
      }
    }
  },

  watch: {
    annotation(newVal, old) {
      if (newVal.id === old.id) {
        this.isExtraInfo = true
      } else {
        const formData = { units: [{ id: newVal.id }] }
        objectsApi.monitoringExtra(
          { formData },
          (response) => {
            this.extraInfo = response.data
            if (!response.data.length) {
              console.log('should hide annotation1')
            }
          },
          (error) => {
            console.log(error)
          }
        )
      }
    }
  },

  methods: {
    toggleItem(idx) {
      if (this.activeIndex === idx) {
        this.activeIndex = null
      } else {
        this.activeIndex = idx
      }
    },
    filterRowWithAddress(row) {
      return row.filter((el) => el.key === 'address')
    },

    filterRowWithoutAddress(row) {
      return row.filter((el) => el.key !== 'address')
    },

    handlerClose(event) {
      // добавил 2 нижних свойства чтобы убрать ошибку в консоли
      event.stopPropagation()
      event.preventDefault()
      this.isExtraInfo = false
      this.extraInfo = []
      this.$emit('close')
    },

    showExtra() {
      this.isExtraInfo = !this.isExtraInfo
      // if (this.isExtraInfo) {
      //   this.isExtraInfo = false
      // } else {
      //   objectsApi.monitoringExtra(
      //     { formData },
      //     (response) => {
      //       this.extraInfo = response.data
      //       this.isExtraInfo = true
      //     },
      //     (error) => {
      //       console.log(error)
      //     }
      //   )
      // }
    },
    getTimeAgo(date) {
      return getLastUpdateTimeObject(this.currentTime, date, this.me.lang.key)
    },
    rowValue(row, index) {
      if (row.key === 'datepoint') {
        return `${row.value} ( ${this.getTimeAgo(
          this.annotation.columns[index].value
        )} ${this.$t('back')} )`
      }

      return row.value
    },
    getRowName(index) {
      if (index === 'gps') return this.$t('annotation.gps_data')
      if (index === 'sensors') return this.$t('annotation.sensors')
      if (index === 'raw_params') return this.$t('annotation.last_params')
      return this.$t('annotation.additional')
    }
  },

  mounted() {
    const el = L.DomUtil.get('annotation_container')
    L.DomEvent.on(el, 'mousewheel', L.DomEvent.stopPropagation)
    const cont = document.getElementById('annotation_container')
    cont.addEventListener('wheel', function (e) {
      e.stopPropagation()
    })
    const formData = { units: [{ id: this.annotation.id }] }

    this.currentWidth = this.$refs.infoBox.clientWidth
    this.height = this.$refs.infoBox.clientHeight
    this.screenHeight = window.innerHeight

    objectsApi.monitoringExtra(
      { formData },
      (response) => {
        this.extraInfo = response.data
        if (!response.data.length) {
          console.log('')
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }
}
</script>
<style>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  opacity: 1;
  max-height: 250px;
}

.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  height: auto;
  max-height: 0;
}

.col .el-collapse-item__wrap {
  border: 2px solid red !important;
}
</style>
<style lang="stylus">
.annotation_container
  &__title
    word-break break-word
    margin-right 8px
    display block
  &__time-ago
    display inline-block
    font-weight: 400;
    font-size: 14px;
    color: #828D9B;
    vertical-align super
.annotation__container {
  z-index 9
  padding-bottom 10px
  width: 408px
  height: auto
  max-height calc(100vh - 122px)
  // top:40px
  position: fixed
  top: 80px
  right: 70px
  background: white
  overflow-x hidden
  .header {
    padding: 10px 20px;
    padding-right 0
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    border-bottom: 1px solid #BEC7D3;
    position sticky
    top 0
    background white
    z-index 1

    span {
      display inline-block
      font-weight: 400;
      font-size: 14px;
      color: #828D9B;
    }
    .header_info {
      display flex
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      div {
        width: 100%
        display flex
        align-items center
      }
      .title {
          margin-right 10px
          margin-bottom 5px
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          max-width: 260px;
          word-break break-word;
        }
    }
  }
}
.annotation__body-container{
  display: flex
  flex-direction column
  padding 10px 20px
  .title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
  }
}

.el-collapse, .el-collapse-item__wrap, .el-collapse-item__header {
  border: none;
}

.without-main-row .el-collapse-item__header {
  background-color: #f2f2f2;
}

.report-annotation .el-collapse-item__header:nth-child(1) {
  background-color: #f2f2f2;
}

.report-annotation .report-details .el-collapse-item__header {
  background-color: #fff;
}

.title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
}

.sub-title {
  background-color: #f2f2f2;
  font-size: 12px;
}

.mini-title {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 13px;
  color: #333333;
}

.mini-title-value {
  font-family: Lato;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 13px;
  color: #677785;
}

.collapse-title {
  color: #91b4e7;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.collapse-time {
  color: #677785;
}
</style>
