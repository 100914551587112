import moment from 'moment'

const getLastUpdateTimeObject = (currentTime, updateDate, locale = 'ru') => {
  if (locale === 'kz') {
    moment.locale('kk')
  } else {
    moment.locale(locale)
  }

  const now = moment(currentTime)
  const duration = moment.duration(now.diff(updateDate))

  if (duration > 2600000 && duration < 3600000) {
    const fixMinute = moment.duration(2600000).humanize().toString()
    const text = fixMinute.split('43 ')[1]
    const minute = Math.floor(duration / 60000)

    return `${minute} ${text}`
  }

  return moment.duration(duration._milliseconds).humanize()
}

export default getLastUpdateTimeObject
